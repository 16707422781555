import gql from 'graphql-tag'

export const getGlobal = gql`
  query GetGlobal {
    allSections {
      id
      pageTitle
      slug
      childSections {
        id
        pageTitle
        slug
      }
    }
    allEventDownloads {
      id
      label
      link
      file {
        url
      }
    }
    footer {
      logos {
        image {
          responsiveImage(imgixParams: { fm: webp }) {
            width
            height
            src
          }
        }
        link
        text
        id
      }
    }
  }
`
