export const getRoutes = `
  query GetRoutes($locale: SiteLocale) {
    allSections(locale: $locale) {
      id
      slug
      childSections {
        id
        slug
      }
    }
  }
`
