import gql from 'graphql-tag'

export const getHome = gql`
  query GetHome($locale: SiteLocale) {
    home(locale: $locale) {
      seo {
        description
        title
        image {
          responsiveImage(imgixParams: { w: "1200", h: "630", fm: jpg }) {
            src
          }
        }
      }
      id
      pageTitle
      pageText(markdown: false)
      landscape: headerImage {
        responsiveImage(
          imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
        }
      }
      portrait: headerImage {
        responsiveImage(
          imgixParams: { fit: crop, w: 1000, h: 1500, ar: "2/3", fm: webp }
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
        }
      }
      sections {
        slug
        pageTitle
        description
        previewImage {
          responsiveImage(
            imgixParams: { fit: crop, w: 1000, h: 1500, ar: "2/3", fm: webp }
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
          }
        }
      }
      reviews {
        review
        rating
        name
        id
      }
    }
  }
`
