import * as home from './home.js'
import * as section from './section.js'
import * as global from './global.js'
import * as sitemap from './sitemap.js'

export default {
  home,
  section,
  global,
  sitemap,
}
