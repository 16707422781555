import gql from 'graphql-tag'

export const getSection = gql`
  query GetSection($locale: SiteLocale, $filter: SectionModelFilter) {
    section(locale: $locale, filter: $filter) {
      pageTitle
      description
      content
      id
      seo {
        description
        title
        image {
          responsiveImage(imgixParams: { w: "1200", h: "630", fm: jpg }) {
            src
          }
        }
      }
      childSections {
        id
        slug
        pageTitle
        description
        previewImage {
          responsiveImage(
            imgixParams: { fit: crop, w: 1000, h: 1500, ar: "2/3", fm: webp }
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
          }
        }
      }
    }
  }
`

export const getSubSection = gql`
  query GetSubSection(
    $locale: SiteLocale
    $filter: SubsectionModelFilter
    $parentFilter: SectionModelFilter
  ) {
    subsection(locale: $locale, filter: $filter) {
      pageTitle
      description
      content(markdown: true)
      id
      downloadLabel
      downloadFile {
        url
      }
      landscape: headerImage {
        responsiveImage(
          imgixParams: { fit: crop, w: 1920, h: 1080, ar: "16/9", fm: webp }
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
        }
      }
      portrait: headerImage {
        responsiveImage(
          imgixParams: { fit: crop, w: 1000, h: 1500, ar: "2/3", fm: webp }
        ) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
        }
      }
      seo {
        description
        title
        image {
          responsiveImage(imgixParams: { w: "1200", h: "630", fm: jpg }) {
            src
          }
        }
      }
    }
    section(filter: $parentFilter) {
      childSections {
        id
        slug
        pageTitle
        description
        previewImage {
          responsiveImage(
            imgixParams: { fit: crop, w: 1000, h: 1500, ar: "2/3", fm: webp }
          ) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
          }
        }
      }
    }
  }
`
